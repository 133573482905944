import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Video from '../../../components/Video';

const SaraFrisco = () => (
  <Layout
    title="3M Senior Product Development Engineer - Sara Frisco"
    className="science-starters careers has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../science-at-work">Science at Work</Link>
          </li>
          <li className="breadcrumb active">Sara Frisco</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">
              3M Senior Product Development Engineer
            </h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../tesha-alston-dampier" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <Video
                guidSrc="57fcee0d-01b9-4935-930f-080117c102ce"
                className="de-vid"
              ></Video>
              <Link to="../jeff-emslander" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pb-6">
      <Container>
        <Row>
          <Column size={7} offset={1} className="career-left">
            <h2>Innovating Lifechanging Solutions in the 3M Lab</h2>
            <p>
              In her role as Senior Product Development Engineer, Sara spends a
              lot of time in the lab experimenting with innovative solutions and
              collaborating with a diverse team of scientists and specialists.
              Sara has seen firsthand how science can be used to improve lives
              with her experience volunteering to help implement STEM in remote
              Indian villages.
            </p>
            <h3>3 Things I Love About My Job</h3>
            <ol>
              <li>
                <strong className="text-bold">
                  Developing useful products that meet and exceed customer
                  needs.
                </strong>
                <br />
                Working in 3M’s Application Area, Sara troubleshoots products
                and experiments with ideas that will eventually lead to new
                inventions and patents.
              </li>
              <li>
                <strong className="text-bold">
                  Being connected to an amazing network of unique and passionate
                  scientists.
                </strong>
                <br /> The ideas and inspiration of her 3M collaborators provide
                a “foundation of innovation” that Sara can tap into to fuel her
                own creative process.
              </li>
              <li>
                <strong className="text-bold">
                  Continually learning and improving through professional
                  development opportunities.
                </strong>
                <br /> Sara uses the volunteer opportunities available through
                the 3M Impact Program to connect with others and develop her own
                capabilities as a scientist.
              </li>
            </ol>
          </Column>
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="global__profile-image">
                <Image filename="Sara-Frisco-mentor-quote.png" />
              </div>
              <div className="global__profile-content">
                <h4 className="quote">
                  “I love the fact that I’m able to integrate my passion for
                  mentoring kids about science into my role at 3M.”
                </h4>
                <h3>Sara Frisco</h3>
                <p>
                  3M Senior Product Development Engineer Commercial Solutions
                  Division
                </p>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default SaraFrisco;
